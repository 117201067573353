export default function App() {
	return (
		<div className='App'>
			<header className='App-header'>
				<p>Under construction, please come back another day :)</p>
				<a
					className='App-link'
					href='https://reactjs.org'
					target='_blank'
					rel='noopener noreferrer'
				>
					Learn React
				</a>
			</header>
		</div>
	);
}
